<template>
	<div class="vc-toolbar align-items-center">
		<div class="align-items-center">
			<MessageBubbleIcon :color="color" />
			<span class="pl--10 text--uppercase text--bold">{{ title }}</span>
		</div>
		<div>
			<IconButton
				v-for="button in iconButtons"
				:key="button.id"
				:iconType="button.iconType"
				:actionHandler="button.actionHandler"
				:cssClass="button.cssClass"
			/>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import { DEFAULT_TITLE } from "../../constants";
import { getThemeColor } from "../../utils";
import MessageBubbleIcon from "../SvgIcons/MessageBubbleIcon";
import IconButton from "../IconButton";
import "./Toolbar.scss";
import { apiEnd } from "../../api";

export default {
	name: "Toolbar",
	data: ({ toggleDropdownMenu, handleToogleChatbot, resizeWindowHandler }) => ({
		iconButtons: [
			{
				id: 1,
				iconType: "resize-arrows",
				actionHandler: resizeWindowHandler,
				cssClass: "mobile-hidden"
			},
			{
				id: 2,
				iconType: "vertical-dots",
				actionHandler: toggleDropdownMenu
			},
			{
				id: 3,
				iconType: "cross",
				actionHandler: handleToogleChatbot,
				cssClass: window.vocallsChatbot
					? window.vocallsChatbot.noClose
						? "hidden"
						: ""
					: ""
			}
		],
		color: getThemeColor(),
		title: window.vocallsChatbot
			? window.vocallsChatbot.title
				? window.vocallsChatbot.title
				: DEFAULT_TITLE
			: DEFAULT_TITLE
	}),
	components: {
		MessageBubbleIcon,
		IconButton
	},
	methods: {
		...mapMutations([
			"toggleDropdownMenu",
			"toggleChatbot",
			"toggleWideMode",
			"toggleEndChatDialog"
		]),

		handleToogleChatbot() {
			if (
				window.vocallsChatbot &&
				window.vocallsChatbot.closeConfirmation === "true"
			) {
				this.toggleEndChatDialog();
			} else {
				if (!this.isConversationEnded) apiEnd();
				this.toggleChatbot();
			}
		},

		resizeWindowHandler() {
			this.toggleWideMode();

			const itemSettings = this.isWideModeEnabled
				? { id: 4, iconType: "arrow-bottom-right" }
				: { id: 1, iconType: "resize-arrows" };

			this.$set(this.iconButtons, 0, {
				...itemSettings,
				actionHandler: this.resizeWindowHandler,
				cssClass: "mobile-hidden"
			});
		}
	},
	computed: {
		...mapGetters(["isWideModeEnabled", "isConversationEnded"])
	}
};
</script>
