<template>
	<div id="vc-chatbot-container">
		<ToggleController
			v-show="!isChatbotOpen"
			:actionHandler="initalizeChatbot"
		/>
		<ChatWindow v-show="isChatbotOpen" />
		<WindowOverlay
			v-show="(isWideModeEnabled && isChatbotOpen) || isEndChatDialogActive"
			:deep="isEndChatDialogActive"
		/>
		<ChatCloseDialog v-if="initializationDone" />
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import { apiInit } from "../../api";
import {
	COOKIE_BASE_NAME,
	STORAGE_BASE_NAME,
	WINDOW_STATE_OPEN,
	WINDOW_STATE_MAXIMIZED,
	WINDOW_STATE_OPEN_AND_MAXIMIZED
} from "../../constants";
import { getMessage, getApiKey } from "../../utils";
import ChatWindow from "../ChatWindow";
import ToggleController from "../ToggleController";
import WindowOverlay from "../WindowOverlay";
import ChatCloseDialog from "../ChatCloseDialog";

export default {
	name: "ChatbotContainer",
	components: {
		ChatWindow,
		ToggleController,
		WindowOverlay,
		ChatCloseDialog
	},
	data: () => ({
		messageGetterInterval: null,
		autoOpenTimeout: null,
		initializationDone: false
	}),
	methods: {
		...mapMutations([
			"toggleChatbot",
			"toggleWideMode",
			"setMessages",
			"startMessageGetterInterval",
			"stopMessageGetterInterval",
			"setMessageActiveInput",
			"setAllMessagesRenderedState",
			"setLanguage",
			"setApiToken"
		]),

		initalizeChatbot() {
			const { toggleChatbot, setAllMessagesRenderedState } = this;
			const isNoCacheSet = window.vocallsChatbot
				? window.vocallsChatbot.noCache
				: false;

			this.autoOpenTimeout ? clearInterval(this.autoOpenTimeout) : null;

			toggleChatbot();

			if (
				!localStorage.getItem(`${COOKIE_BASE_NAME}_${getApiKey()}`) ||
				!localStorage.getItem(`${STORAGE_BASE_NAME}_${getApiKey()}`) ||
				isNoCacheSet === "true"
			) {
				localStorage.removeItem(`${STORAGE_BASE_NAME}_${getApiKey()}`);

				apiInit().then(({ data }) => {
					this.setApiToken(data.sessionId);
					this.setMessageActiveInput(true);
					getMessage();
				});
			} else {
				const token = localStorage.getItem(
					`${COOKIE_BASE_NAME}_${getApiKey()}`
				);
				this.setApiToken(token);

				const storageKey = `${STORAGE_BASE_NAME}_${getApiKey()}`;
				const storageContent =
					window.vocallsChatbot &&
					window.vocallsChatbot.useLocalStorage === "true"
						? localStorage.getItem(storageKey)
						: sessionStorage.getItem(storageKey);

				this.setMessages(JSON.parse(storageContent));
			}

			setAllMessagesRenderedState();
		}
	},
	computed: {
		...mapGetters([
			"isChatbotOpen",
			"isDropdownMenuOpen",
			"messages",
			"isWideModeEnabled",
			"apiToken",
			"isEndChatDialogActive"
		])
	},
	watch: {
		messages: {
			deep: true,
			handler(newData) {
				const storageName = `${STORAGE_BASE_NAME}_${getApiKey()}`;
				const storageContent = JSON.stringify(newData);

				if (
					window.vocallsChatbot &&
					window.vocallsChatbot.useLocalStorage === "true"
				) {
					localStorage.setItem(storageName, storageContent);
				} else {
					sessionStorage.setItem(storageName, storageContent);
				}
			}
		},
		isChatbotOpen(isOpen) {
			if (!this.apiToken) {
				return;
			}

			isOpen
				? this.startMessageGetterInterval()
				: this.stopMessageGetterInterval();
		}
	},
	mounted() {
		if (window.vocallsChatbot) {
			const windowConfigState = window.vocallsChatbot.windowState;

			window.vocallsChatbot.language
				? this.setLanguage(window.vocallsChatbot.language)
				: null;

			if (window.vocallsChatbot.openAfter) {
				this.autoOpenTimeout = setTimeout(
					() => this.initalizeChatbot(),
					window.vocallsChatbot.openAfter
				);
			}

			if (windowConfigState === WINDOW_STATE_OPEN) {
				this.initalizeChatbot();
			}

			if (windowConfigState === WINDOW_STATE_MAXIMIZED) {
				this.toggleWideMode();
			}

			if (windowConfigState === WINDOW_STATE_OPEN_AND_MAXIMIZED) {
				this.initalizeChatbot();
				this.toggleWideMode();
			}

			this.initializationDone = true;
		}
	}
};
</script>
