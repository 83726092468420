<template>
	<DialogWindow
		:value="isEndChatDialogActive"
		:title="confirmation"
		:actions="actions"
		@close="toggleEndChatDialog"
		>{{ text }}</DialogWindow
	>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import DialogWindow from "../DialogWindow";
import { getThemeColor } from "../../utils";
import { mapTranslation } from "../../localization";
import { apiEnd } from "../../api";

export default {
	name: "ChatCloseDialog",

	data: ({ endChatHandler }) => ({
		actions: [
			{
				id: 0,
				label: mapTranslation("yes"),
				handler: endChatHandler,
				color: getThemeColor()
			},
			{ id: 1, label: mapTranslation("no"), handler: () => null }
		],
		confirmation: mapTranslation("confirmation"),
		text: mapTranslation("chatCloseConfirmation")
	}),

	components: {
		DialogWindow
	},

	computed: {
		...mapGetters(["isEndChatDialogActive", "isConversationEnded"])
	},

	methods: {
		...mapMutations(["toggleEndChatDialog", "toggleChatbot"]),

		endChatHandler() {
			if (!this.isConversationEnded) apiEnd();
			this.toggleChatbot();
		}
	}
};
</script>
