import axios from "axios";
import { getApiKey, getApiUrl, getUrlParameters } from "../utils";
import store from "../store";
import getBrowserParameters from "../utils/getBrowserParameters";

export const apiInit = async () => {
	const customParameters = window.vocallsChatbot
		? window.vocallsChatbot.customParams
		: [];
	let initParams = [];

	if (
		window.vocallsChatbot &&
		window.vocallsChatbot.onInit &&
		typeof window[window.vocallsChatbot.onInit] === "function"
	) {
		initParams = await handleCustomInitFunction();
	}
	store.commit("setConversationEnd", false);
	return axios.post(`${getApiUrl()}/${getApiKey()}/init`, {
		parameters: [...getUrlParameters(), ...customParameters, ...initParams],
		browserData: getBrowserParameters()
	});
};

export const apiEnd = async () => {
	if (
		window.vocallsChatbot &&
		window.vocallsChatbot.onEnd &&
		typeof window[window.vocallsChatbot.onEnd] === "function"
	) {
		await window[window.vocallsChatbot.onEnd](store.getters.apiToken);
	}

	axios.post(`${getApiUrl()}/${getApiKey()}/end`, null, {
		headers: {
			"Vocalls-SessionId": store.getters.apiToken
		}
	});
};

export const getMessage = () =>
	axios.get(`${getApiUrl()}/${getApiKey()}/message`, {
		headers: {
			"Vocalls-SessionId": store.getters.apiToken
		}
	});

export const sendMessage = (value, type) =>
	axios.post(
		`${getApiUrl()}/${getApiKey()}/message`,
		{
			Type: type,
			Value: value
		},
		{
			headers: {
				"Content-type": "application/json",
				"Vocalls-SessionId": store.getters.apiToken
			}
		}
	);

export const getAdressFromCoords = ({ lat, lng }) =>
	axios.get(
		`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBb3mWAC23Tqw5IBhZ81Rj6HK3th49TQp8`
	);

export const handleCustomInitFunction = async () => {
	try {
		const data = await window[window.vocallsChatbot.onInit]();

		if (typeof data === "object" && !Array.isArray(data) && data !== null) {
			return Object.entries(data).map(([key, value]) => ({ name: key, value }));
		} else {
			return [];
		}
	} catch {
		return [];
	}
};
